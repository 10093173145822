import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiSignIn } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { initialState, setUser } from "store/auth/userSlice";
import { deleteToken } from "../../services/NotificationService";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const { userName } = useSelector((state) => state.auth.user);

  const signIn = async (payload) => {
    try {
      const { accessToken, user } = await apiSignIn(payload);
      if (accessToken) {
        dispatch(onSignInSuccess(accessToken));
        const payload = {
          id: user.id,
          userName: user.username,
          authority: user.roles,
          permissions: user.permissions,
          email: user.email,
          avatar: "/img/avatars/default.jpg",
          profile: user.profile,
        };
        dispatch(setUser(payload));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        type: "ERROR",
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
    } catch (errors) {}
  };

  const signOut = async () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    await deleteToken(userName);
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
