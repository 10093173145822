import axios from "axios";
import { Notification, toast } from "components/ui";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import HttpService from "./HttpService";

export const getProvinces = async () => {
  const result = await HttpService.get("masterdata/api/province");
  return result?.data ?? [];
};

export const getDistrictByProvinceId = async (provinceId) => {
  const result = await HttpService.get(
    `masterdata/api/district/province/${provinceId}`,
  );
  return result?.data ?? [];
};

export const getWardByDistrictId = async (districtId) => {
  const result = await HttpService.get(
    `masterdata/api/ward/district/${districtId}`,
  );
  return result?.data ?? [];
};

export const uploadFile = async (file, url) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await HttpService.post(url, formData);
    toast.push(
      <Notification
        type="success"
        title="Tệp đã được tải lên thành công!"
      ></Notification>,
      {
        placement: "top-center",
      },
    );
    return response;
  } catch (error) {
    toast.push(
      <Notification type="danger" title="Tệp tải lên không thành công!">
        Vui lòng chọn lại tệp tin!
      </Notification>,
      {
        placement: "top-center",
      },
    );
    throw error;
  }
};

export const downloadBlobFile = async (url, data, fileName, params) => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);
  const { token } = persistData.auth.session;
  const response = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "blob",
    params: params,
  });
  const type = response.headers["content-type"];
  const blob = new Blob([response.data], { type: type });
  const link1 = document.createElement("a");
  link1.href = window.URL.createObjectURL(blob);
  link1.setAttribute("download", fileName);
  document.body.appendChild(link1);
  link1.click();
};

export const downloadBlobFileContract = async (url, fileName, data) => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);
  const { token } = persistData.auth.session;
  const response = await axios.get(url, {
    params: data,
    headers: { Authorization: `Bearer ${token}` },
    responseType: "blob",
  });
  const type = response.headers["content-type"];
  const blob = new Blob([response.data], { type: type });
  const link1 = document.createElement("a");
  link1.href = window.URL.createObjectURL(blob);
  link1.setAttribute("download", fileName);
  document.body.appendChild(link1);
  link1.click();
};

export const downloadFileS3 = async (key) => {
  const res = await HttpService.get("", {
    params: { key },
  });

  return res;
};
export const pagingClient = (data, pageIndex, pageSize) => {
  const start = (Number(pageIndex) - 1) * Number(pageSize);
  const end = Number(pageIndex) * Number(pageSize);
  return data?.slice(start, end);
};

export const apiGetNotificationList = async () => {
  console.log();
};
export const apiGetNotificationCount = async () => {
  console.log();
};

export const uploadFileS3 = async () => {
};