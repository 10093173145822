import { SIGNIN_TYPE } from "constants/app.constant";
import HttpService from "./HttpService";

export async function apiSignIn(data) {
  const { signInType } = data;

  return signInType === SIGNIN_TYPE.DEFAULT
    ? HttpService({
        url: "api/v1/sign-in",
        method: "post",
        data,
      })
    : HttpService({
        url: "api/v1/sign-in",
        method: "post",
        data,
      });
}

export async function apiSignUp(data) {
  return HttpService({
    url: "api/v1/sign-up",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return HttpService({
    url: "api/v1/forgot-password",
    method: "post",
    data,
  });
}
export async function apiChangePassword(data) {
  return HttpService({
    url: "api/v1/change-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return HttpService({
    url: "api/v1/reset-password",
    method: "post",
    data,
  });
}
export async function apiSendOTP(data) {
  return HttpService({
    url: "api/v1/send-otp",
    method: "post",
    data,
  });
}
export async function apiVerifyOTP(data) {
  return HttpService({
    url: "api/v1/verify-otp",
    method: "post",
    data,
  });
}

export const getUsersByUsername = (params) =>
  HttpService.get("api/v1/users", { params });

export const getRoles = (params) => HttpService.get("api/v1/roles", { params });

export const getPermissions = (params) =>
  HttpService.get("api/v1/permissions", { params });

export const addRolePermission = (data) =>
  HttpService.put("api/v1/add-role-permission", data);

export const removeRolePermission = (data) =>
  HttpService.put("api/v1/remove-role-permission", data);

export const exportUser = (data) =>
  HttpService.post("api/v1/export-user-role", data);

export const exportPermission = (data) =>
  HttpService.post("api/v1/export-role-permission", data);

export const updateUserRole = (data) =>
  HttpService.put("api/v1/update-user-role", data);

export const getT24Account = () =>
  HttpService.get("api/v1/customers/mapping-account");

export const createUser = (data) => HttpService.post("api/v2/user", data);

export const updateUser = (userId, data) => {
  const updateDto = {
    ...data,
    profile: {
      firstName: data?.firstName,
      lastName:  data?.lastName,
      position:  data?.position,
      department:  data?.department,
      gender: data?.gender
    }
  };
  HttpService.patch(`aaa/api/v1/user/${userId}`, updateDto);
}

export const verifyEmail = (token) => {
  return HttpService.post(`api/v1/verify/email?token=${token}`);
};

export const verifyForgotPassword = (token, payload) => {
  HttpService.post(`api/v1/verify/forgot-pwd?token=${token}`, payload);
};

export const requestForgotPassword = (payload) => {
  HttpService.post(`api/v1/forgot-password`, payload);
};

export const createRole = (data) => {
  return HttpService.post("aaa/api/v1/role", data);
}

export const createPermission = (data) => {
  return HttpService.post("aaa/api/permission", data);
}