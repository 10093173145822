export const ORDER_STATUS = [
  {
    statusCode: 0,
    orderStatusGroup: "0",
    statusGroupName: "",
    name: "",
    nameRec: "",
  },
  {
    statusCode: 1,
    orderStatusGroup: "1",
    statusGroupName: "Đã tạo",
    name: "Đã tạo",
    nameRec: "Đã tạo",
  },
  {
    statusCode: 2,
    orderStatusGroup: "2",
    statusGroupName: "Chờ lấy hàng",
    name: "Bưu tá nhận yêu cầu thu gom",
    nameRec: "Bưu tá nhận yêu cầu thu gom",
  },
  {
    statusCode: 3,
    orderStatusGroup: "2",
    statusGroupName: "Chờ lấy hàng",
    name: "Chờ xác nhận",
    nameRec: "Chờ xác nhận",
  },
  {
    statusCode: 4,
    orderStatusGroup: "3",
    statusGroupName: "Nhận hàng",
    name: "Đang lấy hàng",
    nameRec: "Đang lấy hàng",
  },
  {
    statusCode: 5,
    orderStatusGroup: "3",
    statusGroupName: "Nhận hàng",
    name: "Lấy hàng không thành công",
    nameRec: "Lấy hàng không thành công",
  },
  {
    statusCode: 6,
    orderStatusGroup: "3",
    statusGroupName: "Nhận hàng",
    name: "Đã lấy hàng",
    nameRec: "Đã lấy hàng",
  },
  {
    statusCode: 7,
    orderStatusGroup: "3",
    statusGroupName: "Nhận hàng",
    name: "Bưu cục đã nhận hàng",
    nameRec: "Bưu cục đã nhận hàng",
  },
  {
    statusCode: 8,
    orderStatusGroup: "4",
    statusGroupName: "Huỷ",
    name: "Lấy hàng thất bại",
    nameRec: "Lấy hàng thất bại",
  },
  {
    statusCode: 9,
    orderStatusGroup: "4",
    statusGroupName: "Huỷ",
    name: "Hủy thu gom",
    nameRec: "Hủy thu gom",
  },
  {
    statusCode: 10,
    orderStatusGroup: "5",
    statusGroupName: "Đang vận chuyển",
    name: "Đang vận chuyển",
    nameRec: "Đang vận chuyển",
    colorClassName: "text-orange-300",
  },
  {
    statusCode: 11,
    orderStatusGroup: "6",
    statusGroupName: "Đang giao hàng",
    name: "Đã đến bưu cục phát",
    nameRec: "Đã đến bưu cục phát",
    colorClassName: "text-orange-300",
  },
  {
    statusCode: 12,
    orderStatusGroup: "6",
    statusGroupName: "Đang giao hàng",
    name: "Đang giao hàng",
    nameRec: "Đang giao hàng",
    colorClassName: "text-orange-300",
  },
  {
    statusCode: 13,
    orderStatusGroup: "6",
    statusGroupName: "Đang giao hàng",
    name: "Chuyển tiếp",
    nameRec: "Chuyển tiếp",
    colorClassName: "text-orange-300",
  },
  {
    statusCode: 14,
    orderStatusGroup: "8",
    statusGroupName: "Đã giao hàng",
    name: "Đã giao hàng",
    nameRec: "Đã giao hàng",
    colorClassName: "text-lime-500",
  },
  {
    statusCode: 15,
    orderStatusGroup: "10",
    statusGroupName: "Giao không thành công",
    name: "Giao không thành công",
    nameRec: "Giao không thành công",
    colorClassName: "text-rose-500",
  },
  {
    statusCode: 16,
    orderStatusGroup: "7",
    statusGroupName: "Chuyển hoàn",
    name: "Chờ để chuyển hoàn",
    nameRec: "Chờ để chuyển hoàn",
    colorClassName: "text-fuchsia-500",
  },
  {
    statusCode: 18,
    orderStatusGroup: "7",
    statusGroupName: "Chuyển hoàn",
    name: "Bắt đầu chuyển hoàn",
    nameRec: "Bắt đầu chuyển hoàn",
    colorClassName: "text-fuchsia-500",
  },
  {
    statusCode: 19,
    orderStatusGroup: "7",
    statusGroupName: "Chuyển hoàn",
    name: "Trả hàng thành công",
    nameRec: "Trả hàng thành công",
    colorClassName: "text-fuchsia-500",
  },
  {
    statusCode: 21,
    orderStatusGroup: "9",
    statusGroupName: "Đối soát",
    name: "Đã trả tiền COD",
    nameRec: "Đã trả tiền COD",
    colorClassName: "text-green-600",
  },
  {
    statusCode: 22,
    orderStatusGroup: "4",
    statusGroupName: "Huỷ",
    name: "Hủy giao hàng",
    nameRec: "Hủy giao hàng",
    colorClassName: "text-gray-600",
  },
  {
      statusCode: 23,
      orderStatusGroup: '2',
      statusGroupName: 'Ship Hà Nội',
      name: 'Gửi bưu điện',
      nameRec: 'Gửi bưu điện',
  },
  {
      statusCode: 24,
      orderStatusGroup: '9',
      statusGroupName: 'Ship Hà Nội',
      name: 'Đã thanh toán ship',
      nameRec: 'Đã thanh toán ship',
  },
];
