import { Progress, Tooltip } from "components/ui";
import { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { downloadFileS3, uploadFileS3 } from "services/CommonService";
import { isValidUrl } from "utils/helper/helper";
import useThemeClass from "../../../utils/hooks/useThemeClass";
import Input from "../Input";

export const InputUpload = ({
  value,
  onChange,
  showIcon = true,
  sliceSize = -35,
  showDownload = true,
  disabled = false,
  isHaveUrl = false,
}) => {
  const { textTheme } = useThemeClass();
  const [progress, setProgress] = useState(0);
  const [isOnChange, setIsOnChange] = useState(false);
  const onUploadFile = async (event) => {
    try {
      setIsOnChange(true);
      setProgress(0);
      const resourse = await uploadFileS3(
        event.currentTarget.files[0],
        (number) => setProgress(number),
      );
      onChange && onChange(resourse);
    } catch (e) {}
  };
  const onDownload = async () => {
    if (isHaveUrl) {
      window.open(value, "_blank");
      return;
    }
    if (isValidUrl(value)) {
      window.open(value, "_blank");
    } else {
      const { signedUrl } = await downloadFileS3(value);
      window.open(signedUrl, "_blank");
    }
  };
  const onRemove = () => {
    onChange && onChange("");
    setIsOnChange(false);
  };
  // console.log(value);
  useEffect(() => {
    setProgress(0);
  }, [value]);
  return (
    <div>
      {value && (
        <div className="grid grid-cols-6">
          {showDownload ? (
            <>
              <div
                className={`col-start-1 col-span-5 ... ${textTheme} cursor-pointer font-semibold`}
              >
                <a onClick={onDownload}>...{value.slice(sliceSize)}</a>
              </div>
            </>
          ) : (
            <>
              <div className="col-start-1 col-span-5 ...">
                <a
                  className="font-bold cursor-pointer p-2 text-blue-500"
                  onClick={onDownload}
                >
                  Tải xuống
                </a>
              </div>
            </>
          )}
          <div>
            {showIcon ? (
              <span
                className="cursor-pointer p-2 hover:text-red-500"
                onClick={() => onRemove()}
              >
                <Tooltip title="Xoá file">
                  <HiTrash />
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {!value && (
        <div>
          <Input
            disabled={disabled}
            size="sm"
            type="file"
            onChange={onUploadFile}
            className="h-auto"
          />
          {isOnChange && progress != 0 && <Progress percent={progress} />}
        </div>
      )}
    </div>
  );
};
